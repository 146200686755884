<template>
  <div class="knowledge-search-page">
    <form  @submit.prevent action="#">
      <van-search
        v-model="params.goods_name"
        show-action
        class="mb10"
        placeholder="搜索"
        @search="onSearch"
        @cancel="onCancel"
      />
    </form>
    <div class="search-content">
      <!-- <sendListItem
        v-for="(item, index) in list"
        :sendItem="item"
        pageType="knowledge"
        :limitNum="5"
        :mediaList="item.file_arr"
        :customer_userid="customer_userid"
        :member_userid="member_userid"
        :index="index"
        :keyArr="keyArr"
        backgroundColor="#fff"
        cutColor="#f5f5f5"
      ></sendListItem> -->
      <van-list
        v-if="list.length || refreshing"
        v-model:loading="loading"
        v-model:error="error"
        :finished="finished"
        :immediate-check="false"
        error-text="请求失败，点击重新加载"
        :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
        @load="onload"
      >
        <GoodsItem
          v-for="item in list"
          :goodsItem="item"
          :key="item.id"
          :member_id="member_id"
        />
      </van-list>
      <div v-if="list.length === 0 && searched" class="search-nodata">
        暂无搜索结果
      </div>
    </div>
  </div>
</template>

<script>
import { Search, List } from 'vant'
import GoodsItem from './components/GoodsItem.vue'
import { getSpeechList } from '../../service/quickSend.service'
import { getShareGoodsList } from '../../service/checkQr.service'
import { getCurExternalContact } from '../../utils/nwechat.js'
export default {
  components: {
    [Search.name]: Search,
    [List.name]: List,
    GoodsItem
  },
  data() {
    return {
      list: [],
      loading: false,
      error: false,
      finished: false,
      params: {
        page: 1,
        page_size: 20,
        goods_name: ''
      },
      keyArr: [],
      searched: false,
      // customer_id: '',
      // requestStatus: null,
      // pollingIndex: 0,
      member_id: ''
    }
  },
  created() {
    // this.onSearch()
    // this.init()
  },
  methods: {
    init() {
      // 轮询请求id
      this.requestStatus = setInterval(() => {
        if (this.customer_id || this.pollingIndex > 3) {
          // 赋值给参数
          // this.customer_id = this.customer_id
          // this.checkCustomer()
          clearInterval(this.requestStatus)
          return
        }
        console.log('现在是第', this.pollingIndex)
        this.pollingIndex++
        getCurExternalContact()
          .then((res) => {
            this.customer_id = res
          })
          .catch(() => {
            this.customer_id = ''
          })
      }, 300)
    },
    // 搜索
    onSearch() {
      // this.list = personalSpeech.data.list || []
      // console.log(this.list)
      this.keyArr[0] = this.params.goods_name
      this.list = []
      this.params.page = 1

      if (!this.params.goods_name) {
        return
      }
      this.searched = true
      this.onload()

      // getSpeechList(this.params)
      //   .then((res) => {
      //     console.log(res)
      //     if (res.code == 200) {
      //       this.list = res.data || []
      //     }
      //   })
      //   .catch((e) => {
      //     console.log(e)
      //   })
    },

    // 加载列表
    async onload() {
      if (this.list.length > 0) {
        this.params.page_num += 1
      }
      try {
        this.loading = true
        const { data, code } = await getShareGoodsList(this.params)

        if (code !== 200) {
          this.error = true
          // this.count = 0
          throw code
        }

        // this.count = data.total_num || 0
        this.list = this.list.concat(data.list)
        this.member_id = data.member_id
        this.loading = false

        // 翻到最后一页
        if (
          this.list.length < this.params.page_size
          // || this.count === this.list.length
        ) {
          this.finished = true
        }
      } catch (error) {
        this.error = true
        this.finished = true
        console.log(error)
      } finally {
        this.loading = false
        return Promise.resolve(true)
      }
    },
    onCancel() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.knowledge-search-page {
  .search-content {
    box-sizing: border-box;
    // width: 295px;
    background-color: #f5f5f5;
    height: calc(100vh - 55px);
    padding: 10px 0;
    overflow-y: scroll;
    position: relative;
  }

  .search-nodata {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    color: #909399;
    text-align: center;
    font-size: 14px;
  }
}
</style>
